:root {
  --backgroundPrimary: black;
  --fontColorPrimary: white;
  --fontColorSecondary: blue;
  --fontColorDanger: red;
  --fontColorGray: gray;
  --shadowColorBottom: #d6d9d9;
  --shadowColorTop: white;
  --blinkingBackground: black;
}

body {
  background: var(--backgroundPrimary);
  color: var(--fontColorPrimary);
}

.title {
  font-family: "Monoton", monospace;
  font-size: 2rem;
  font-weight: 400;
  background: linear-gradient(
    90deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(29, 39, 253, 1) 55%,
    rgba(255, 179, 73, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-align: center;
  margin: auto;
}

.colour {
  background-color: #ff1010;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23ff258d' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23ff3af7' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23b24eff' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%237163ff' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%2378ADFF' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%236393ff' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%234e75ff' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%233853ff' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23232eff' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23170EFF' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.colour-dark {
  background-color: #2901ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%231700e4' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%230901c9' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%230104ad' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23010c91' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23011176' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23010392' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%231401af' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%232f01cc' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%235200e8' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%237C06FF' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.prompt {
  font-family: "Ubuntu Mono", monospace;
  font-size: 20px;
  word-break: break-word;
}

.seenChar {
  color: var(--fontColorGray);
}

.errorChar {
  color: var(--fontColorDanger);
}

.currentChar {
  background: var(--blinkingBackground);
  color: var(--backgroundPrimary);
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    background: var(--blinkingBackground);
    color: var(--backgroundPrimary);
  }
  50% {
    background: transparent;
    color: var(--fontColorPrimary);
  }
  100% {
    background: var(--blinkingBackground);
    color: var(--backgroundPrimary);
  }
}

.unseenChar {
  color: var(--fontColorSecondary);
}
.hiddenTextInput {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 0;
  width: 0;
  overflow: hidden;
  display: block;
  line-height: 0;
}

.promptCard {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  width: min(80%, 700px);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.expected-wpm {
  font-family: "Courier New", Courier, monospace;
  font-size: 2em;
  padding: 15px;
  border-radius: 20px;
  color: var(--fontColorPrimary);
}

.actual-wpm {
  width: 14rem;
}

.hiddenTextInput:not(:focus) ~ .promptCard {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  cursor: pointer;
}

.morphic-drop {
  background: var(--backgroundPrimary);
  box-shadow: 8px 7px 4px var(--shadowColorBottom),
    -7px -7px 14px var(--shadowColorTop);
  box-shadow: 10px 10px 25px var(--shadowColorBottom),
    -15px -15px 30px var(--shadowColorTop);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.button {
  cursor: pointer;
  filter: grayscale();
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  border-radius: 1.2rem;
  padding: 1rem 1rem;
  max-width: 100%;
  color: var(--fontColorPrimary);
  background-color: var(--backgroundPrimary);
  box-shadow: 7px 7px 14px var(--shadowColorBottom),
    -7px -7px 14px var(--shadowColorTop);
}

.button:hover,
.button:focus {
  filter: brightness(85%);
  filter: grayscale(0);
}

.neumorphic-menu {
  z-index: 1;
}

.neumorphic-menu:focus,
.neumorphic-menu:hover .button {
  background: var(--backgroundPrimary);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 7px 7px 7px var(--shadowColorBottom),
    -7px -7px 14px var(--shadowColorTop);
  cursor: pointer;
}

.right {
  margin-right: 1rem;
}

.left {
  margin-left: 1rem;
}

.nav-menu {
  background: var(--backgroundPrimary);
  position: fixed;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  border-radius: 1rem;
  border-top-left-radius: 0;
  box-shadow: 7px 7px 7px var(--shadowColorBottom),
    -7px 0px 3px var(--shadowColorTop);

  cursor: pointer;
}

.nav-menu-right {
  right: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 0;
}

.menu-item:hover {
  color: var(--fontColorSecondary);
  background-color: var(--backgroundPrimary);
}

.menu-item {
  color: var(--fontColorPrimary);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  margin: 0.8rem;
}

.menu-item-right {
  flex-direction: row-reverse;
}

.particles {
  z-index: -999;
  position: fixed;
}

.right-panel {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.blurText {
  color: var(--fontColorPrimary);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 5;
  background-color: black 0.5;
  cursor: pointer;
  border-radius: 2rem;
  width: min(80%, 700px);
  text-align: center;
}

.hiddenTextInput:focus ~ .blurText {
  z-index: -1;
  opacity: 0;
}

.notSelectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.primary-text {
  color: var(--fontColorPrimary);
}

.stats-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.line-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  maxwidth: 80vw;
}

.stats-top-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  width: min(80%, 700px);
}

.timely-profile-stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.spinner {
  /* center horizontally and vertically in its container*/
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-icon {
  cursor: pointer;
  filter: grayscale();
}

.button-icon:hover,
.button-icon:focus {
  filter: brightness(85%);
  filter: grayscale(0);
}

.profile-stats-panels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.stats-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: min(80%, 700px);
}

.nav-item:hover {
  background-color: #3752fc;
  color: white;
}

.chakra-ui-dark .nav-item:hover {
  background-color: #2f01cc;
  color: var(--chakra-colors-white);
}
